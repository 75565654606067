@import '../../../variables'

.VisitForm
  .form-wrapper
    width: 0
    height: 100%
    background-color: rgba(0, 0, 0, 0.5)
    opacity: 0
    z-index: 1
    position: fixed
    cursor: pointer
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms

  .form
    box-shadow: 0px 1px 8px 0px rgba(161, 161, 161, 0.25)
    cursor: initial
    width: 200px
    height: calc(100vh - 52px)
    overflow-y: hidden
    background: white
    position: absolute
    z-index: 1
    transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms
    h1
      font-size: 18px
    .form-inner
      padding: 24px 24px 24px 48px

  &.open
    .form-wrapper
      width: 100%
      opacity: 1

    .form
      width: 530px
      overflow-y: scroll

  .Field > label
    color: $parso-high-gray
    font-size: 14px
    min-width: 96px
  
  .button-drawer-toggle
    position: absolute
    top: 50vh
    left: 185px
    transform: translateY(-50%)
    background-color: white
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3)
    z-index: 3
    display: flex
    align-items: center
    justify-content: center
    transition: left 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
    padding: 8px
    border: 1px solid $parso-blue
    border-radius: 8px
    svg
      width: 16px
      color: $parso-blue
    &:hover
      background-color: white
    &.open
      left: 510px


.webcam-wrapper
  border-radius: 50%
  width: 322px
  height: 322px
  overflow: hidden
  background: black
  border: 8px solid var(--Violet, #6C85D3)
  video, img
    width: 100%
    height: 100%
    object-fit: cover

.webcam-wrapper-ID
  width: 399px
  height: 317px
  overflow: hidden
  background: black
  border: 8px solid var(--Violet, #6C85D3)
  video, img
    width: 100%
    height: 100%
    object-fit: cover

.visitor-dialog
  .MuiPaper-root .MuiDialogContent-root
    padding: 33px 44px

@keyframes enable-display
  from
    display: none
  to
    display: block
