@import '../../variables'
.dialog-reset-password
  .MuiDialogContent-root.MuiDialogContent-root
    padding: 166px 22px
    background-color: $parso-modal-gray
    .dialog-content-inner
      max-width: 400px
      margin: auto
    h1
      font-size: 24px
      font-weight: 700
      color: $parso-black-text
    .MuiButtonBase-root.close-button
      position: absolute
      top: 16px
      right: 16px
      color: black
  @media screen and (min-width: $md)
    .MuiDialogContent-root.MuiDialogContent-root
      padding: 166px 100px
      h1
        font-size: 32px
      .MuiButtonBase-root.close-button
        top: 36px
        right: 51px

.Login
  background: #FAFCFE
  color: $parso-high-gray-blue
  height: 100vh
  grid-template-columns: 60% 40%
  min-height: 560px
  .full-logo
    display: none
  .privacy
    color: $parso-high-gray-blue
    text-decoration: none
    font-size: 11px
    padding-top: 18px
  .cover
    display: none
  .login-inner
    height: 416px
    padding: 0 21px 47px
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    margin: auto
    max-width: 400px
    text-align: center
    .logo
      width: 48px
      margin-bottom: 29px
    h1
      color: $parso-high-gray-blue
      margin-bottom: 27px
    .MuiButton-text
      color: $parso-high-gray-blue
      text-decoration: underline
      font-size: 12px
      font-weight: 500
      line-height: 22px
      margin-bottom: 12px !important
  @media screen and (min-width: $md)
    background: url(../../../public/login-background.png) no-repeat center center fixed
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    background-size: cover
    .full-logo
      display: block
      width: 180px
      position: relative
      top: 63px
      left: 65px
    .privacy
      color: #8C8CA1
    .login-inner
      background-color: white
      box-shadow: 0px 0px 60px rgba(108, 133, 211, 0.15)
      border-radius: 18px
      color: $parso-blue
      width: 504px
      max-width: 530px
      padding: 98px 75px 60px
      box-sizing: border-box
      height: fit-content
      h1
        text-align: left
        font-size: 24px
        margin-bottom: 52px
        color: $parso-black-text
        text-align: center
      .cp-span
        text-align: left
        display: block
        .MuiButton-text
          color: $parso-blue
          float: right
          margin-bottom: 0 !important
          padding: 0
      .cp-label
        text-align: left
        font-size: 16px
        color: $parso-black-ligth-text
      .cp-text-field:first-of-type
        margin-bottom: 20px
