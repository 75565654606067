@import '../../../variables'

.blacklist-dialog
  .blacklist-header
    width: 328px
    text-align: center
    svg
      font-size: 56px
    h2
      color: $parso-warning
      font-size: 20px
      font-weight: 700
  label
    font-size: 16px
    font-weight: 300
