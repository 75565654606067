@import '../../../variables'

.layout
  .content-wrapper
    width: 100%
    & > div.general-scroll
      height: calc(100vh - 103px)
      overflow-y: scroll
  @media screen and (min-width: $md)
    .content-wrapper > div.general-scroll
      height: calc(100vh - 120px)
