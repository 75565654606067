@import '../../variables'

.MuiPaper-root .MuiDialogContent-root.dialog-info-visitor
  text-align: center
  .detail-remove-info
    background: transparent
    margin-bottom: 20px

.visit-list
  margin-left: 180px

.main-wrapper
  padding: 38px 79px
  background: #F6F6F6

.table-wrapper
  .MuiDataGrid-root

    .MuiDataGrid-virtualScroller
      &::-webkit-scrollbar
        width: 10px
        height: 10px
        border-radius: 8px
        background: $parso-drag-drop-blue
        

      &::-webkit-scrollbar-track
        background: #FFF
        border-radius: 8px

.detail-header
  color: $parso-blue
  font-size: 20px
  font-style: normal
  font-weight: 800
  line-height: normal
  margin: 16px

.detail-remove-info
  color: $parso-high-gray
  text-align: center
  font-size: 16px
  width: 334px
  margin-bottom: 20px

.detail-info
  color: $parso-high-gray
  font-size: 14px
  font-weight: 500
  line-height: 22px
  letter-spacing: 0.21px
  span
    color: $parso-denied
