@import '../../variables'

.pre-registers 
  display: flex
  flex-direction: column
  align-items: center
  width: 100vw

.date-picker label
  width: 136px
  font-weight: 300
  font-size: 13px

.MuiTextField-root svg
  width: 20px
  color: #525252

.MuiTextField-root:last-child svg
  color: #C4C4C4

.date-picker .MuiInputBase-root
  width: auto
  
.pre-registers-data-grid
  .MuiDataGrid-cell, .MuiDataGrid-columnHeaders,
  .MuiDataGrid-footerContainer
    font-size: 12px

